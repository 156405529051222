import styled from '@emotion/styled'

import Container from 'react-bootstrap/Container'

export const HeaderContainer = styled.header`
    max-height: 6.25rem;
    height: 6.25rem;
    top: 0;
    transition: transform 0.8s cubic-bezier(0.4, 0, 0, 1) 0.4s;

    &.bg-show {
        color: var(--black);
    }

    &::before {
        content: '';
        z-index: -1;
        background-color: white;
        transform: translate3d(0, -120%, 0);
        transition: transform 0.8s cubic-bezier(0.4, 0, 0, 1) 0.4s;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        box-shadow: 0rem -1.25rem 0rem 1.25rem var(--light-gray);
    }

    &.menu-opened {
        &::before {
            transform: translateZ(0);
            transition-delay: 0s;
        }
    }

    &.bg-show {
        &::before {
            transform: translateZ(0);
            transition-delay: 0s;
        }
    }

    &.hidden {
        transform: translateY(-100%);
    }
`

export const HeaderContent = styled(Container)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid white;
    height: 100%;
    position: relative;
`
