import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { Link } from 'gatsby'

const optionSpacing = `1.625rem` // 26px
const optionHeight = `1.75rem` // 28px
const optionFontSize = `1.1875rem` // 19px

export const iconStyle = css`
    height: ${optionHeight};
    width: auto;
`

const optionStyle = css`
    font-size: 0;
    margin: 0;
    padding: 0;
    color: white;
    font-family: 'Full Sans SC 30', sans-serif;
    margin-right: ${optionSpacing};
    transition: all 250ms cubic-bezier(0.4, 0, 0, 1);

    &:hover {
        text-decoration: none;
        color: white;
        opacity: 0.85;
    }
`

export const Option = styled.a`
    ${optionStyle}

    svg {
        stroke: white;
        transition: all 1.2s cubic-bezier(0.4, 0, 0, 1);
    }

    &.text-black {
        transition-duration: 0.25s;

        svg {
            transition-duration: 0.25s;
            stroke: var(--black);
            fill: var(--black);
        }

        &:hover {
            opacity: 1;
            color: white;
            background-color: var(--black);

            svg {
                stroke: white;
                fill: white;
            }
        }
    }
`

export const OptionLink = styled(Link)`
    ${optionStyle}

    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${optionFontSize};
    margin-right: 0;
    transition-duration: 1.2s;

    &:hover {
        background-color: white;
        color: var(--black);
    }

    &.text-black {
        transition-duration: 0.25s;
        color: var(--black);

        &:hover {
            color: white;
            background-color: var(--black);
        }
    }
`

export const Separator = styled.span`
    display: block;
    width: 0.125rem; /* 2px */
    height: ${optionHeight};
    background-color: white;
    margin-right: ${optionSpacing};
    transition-duration: 1.2s;

    &.text-black {
        background-color: var(--black);
    }
`

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    &.bg-show {
        ${Option} {
            svg {
                transition-duration: 0.25s;
                stroke: var(--black);
                fill: var(--black);
            }
        }

        ${OptionLink} {
            color: var(--black);
        }

        ${Separator} {
            background-color: var(--black);
        }
    }

    @media (max-width: 465px) {
        &:not(.mobile-options) {
            display: none;
            visibility: hidden;
        }
    }
`
