import styled from '@emotion/styled'

export const NavTogglerText = styled.span`
    margin-right: 1rem; /* 10px */
    font-size: 1.1875rem; /* 19px */
    font-family: 'Full Sans SC 30', Arial, sans-serif;
    color: white;
    text-transform: uppercase;
    transition: color 1.2s cubic-bezier(0.4, 0, 0, 1);

    .text-black & {
        color: var(--black);
    }
`

export const NavToggler = styled.div`
    flex-direction: row-reverse;
    align-items: center;
    border: 1px solid white;
    padding: 0.625rem 1.25rem;
    display: none;
    visibility: hidden;
    transition: border-color 1.2s cubic-bezier(0.4, 0, 0, 1);
    cursor: pointer;

    &.text-black {
        border-color: var(--black);
    }

    &.bg-show {
        border-color: var(--black);

        ${NavTogglerText} {
            color: var(--black);
        }
    }

    @media (max-width: 768px) {
        display: flex;
        visibility: visible;
        font-size: 1.25rem;
    }
`
