import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

const HeaderBrand = () => {
    // return <StaticImage src="../../images/logo.svg" />
    return <span style={{ color: 'white' }}></span>
}

export default HeaderBrand
