import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { NavToggler, NavTogglerText } from './mobileNavTogglerStyles'
import Burger from '@animated-burgers/burger-squeeze'

const MobileNavToggler = ({ toggleNav, mobileNavOpened, className }) => (
    <NavToggler className={classnames(className, { 'text-black': !mobileNavOpened })} onClick={toggleNav}>
        <Burger isOpen={!mobileNavOpened} className={className} />
        <NavTogglerText>menü</NavTogglerText>
    </NavToggler>
)

MobileNavToggler.propTypes = {
    toggleNav: PropTypes.func.isRequired,
    mobileNavOpened: PropTypes.bool.isRequired,
    className: PropTypes.string,
}

export default MobileNavToggler
