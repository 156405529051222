import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { Navbar, Nav } from 'react-bootstrap'
import { NavBarContainer, NavLink } from './headerNavbarStyles'

const HeaderNavbar = ({ className }) => {
    return (
        <NavBarContainer bg="transparent" expand="lg" className={className}>
            <Navbar.Collapse>
                <Nav className="mr-auto">
                    <NavLink as={Link} to="/#vendeghaz">
                        vendégház
                    </NavLink>
                    <NavLink as={Link} to="/#szobak">
                        szobák
                    </NavLink>
                    <NavLink as={Link} to="/#arak">
                        árak
                    </NavLink>
                    <NavLink as={Link} to="/#galeria">
                        galéria
                    </NavLink>
                    <NavLink as={Link} to="/#kapcsolat">
                        kapcsolat
                    </NavLink>
                </Nav>
            </Navbar.Collapse>
        </NavBarContainer>
    )
}

HeaderNavbar.propTypes = {
    className: PropTypes.string,
}

export default HeaderNavbar
