import styled from '@emotion/styled'

import { Navbar, Nav } from 'react-bootstrap'

const linkSpacing = `1.875rem` // 26px
const optionHeight = `1.75rem` // 28px
const fontSize = `1.1875rem` // 19px

export const NavLink = styled(Nav.Link)`
    color: white !important;
    font-family: 'Full Sans SC 30', sans-serif;
    font-size: ${fontSize};
    text-transform: uppercase;
    padding: 2.1875rem 0; /* 35px => 100px height */
    position: relative;
    margin-right: ${linkSpacing};
    transition: transform 0.8s cubic-bezier(0.4, 0, 0, 1) 0.4s;

    &:last-of-type {
        margin-right: 0;
    }

    &::after {
        content: '';
        display: block;
        height: 1px;
        background-color: currentColor;
        transform-origin: right center;
        transition: transform 0.3s cubic-bezier(0.3, 0.1, 0.3, 0.1);
        transform: scaleX(0);
        will-change: transform;
    }

    &:hover {
        &::after {
            transform: none;
            transform-origin: left center;
        }
    }
`

export const NavBarContainer = styled(Navbar)`
    padding: 0;

    &.bg-show {
        ${NavLink} {
            color: var(--black) !important;
        }
    }
`
