import React, { useContext, useState } from 'react'
import classnames from 'classnames'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import useIsSsr from '../../hooks/useIsSsr'

import NavbarContext from '../../contexts/navbar/navbar.context'

import { HeaderContainer, HeaderContent } from './headerStyles'
import HeaderNavbar from '../header-navbar/HeaderNavbar'
import HeaderBrand from '../header-brand/HeaderBrand'
import HeaderOptions from '../header-options/HeaderOptions'
import MobileNavToggler from '../mobile-nav-toggler/MobileNavToggler'

const Header = () => {
    const isSsr = useIsSsr()

    const { hidden, toggleHidden } = useContext(NavbarContext)

    const [bg, setBg] = useState(false)
    const [hideHeader, setHideHeader] = useState(false)

    useScrollPosition(
        ({ prevPos, currPos }) => {
            const viewHeight = isSsr ? null : document.documentElement.clientHeight * 0.65
            const position = Math.abs(currPos.y)

            if (!bg && position > viewHeight) {
                setBg(true)
            }

            if (bg && position < viewHeight) {
                setBg(false)
            }

            if (bg && currPos.y < prevPos.y) {
                setHideHeader(true)
            }

            if (bg && currPos.y > prevPos.y) {
                setHideHeader(false)
            }
        },
        [bg, hideHeader]
    )

    if (isSsr) return null

    return (
        <HeaderContainer className={classnames('sticky-top', { 'menu-opened': !hidden, 'bg-show': bg, hidden: hideHeader })}>
            <HeaderContent>
                <HeaderBrand />
                <HeaderNavbar className={classnames({ 'bg-show': bg })} />
                <MobileNavToggler mobileNavOpened={hidden} toggleNav={toggleHidden} className={classnames({ 'bg-show': bg })} />
                {/* <HeaderOptions mobileNavOpened={hidden} className={classnames({ 'bg-show': bg })} /> */}
            </HeaderContent>
        </HeaderContainer>
    )
}

export default Header
